@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  font-family: 'Poppins', Arial, sans-serif;
  background-color: #f0f2f5; 
}
/* Image */
.image-section {
  position: relative; 
  display: block;
  height: auto;
  overflow: hidden;
  margin: 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
  z-index: 1;
}

.background-image {
  width: 100%;
  height: auto; 
  object-fit: cover; 
  transition: transform 0.3s ease;
  border-radius: 8px;
  margin: 10px; 
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
}
.overlay-content h1 {
  font-size: 2.5em; 
  color: #615f04; 
  text-align: center; 
  margin: 0; 
  padding: 10px 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); 
}

.overlay-content h2 {
  font-size: 1.8em; 
  text-align: center; 
  margin: 0; 
  padding: 5px 0; 
  font-weight: normal; 
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4); 
}
/*Facts*/
.info-section {
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  margin: 20px 0; 
  border-radius: 8px; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.info-column {
  flex: 1;
  margin: 0 10px; 
  padding: 20px; 
  border-radius: 8px;
  color: #fff; 
  transition: transform 0.3s; 
}

.info-column:hover {
  transform: translateY(-5px); 
}

.info-section h2 {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 10px; 
}
.info-section p {
  color: #f0f0f0; 
  line-height: 1.8; 
}
.info-section h1{
  text-decoration: underline; 
  text-decoration-color: white; 
  text-underline-offset: 2px; 
}
.info-column:nth-child(1) {
  background: linear-gradient(to right, #405505, #abad0f);
}

.info-column:nth-child(2) {
  background: linear-gradient(to right, #2e2641, #ff6b81);
}
/*Service Section*/
.services-section {
  padding: 20px 50px;
  margin: 20px 0;
 
 
}

.services-section h2 {
text-align: center;
color: rgb(238, 231, 231);
margin: 30px;
overflow: hidden;
  
}

.services-section h2::after {
  content: "SERVICES"; 
  top: -100%; 
  left: 0;
  right: 0;
  color: rgba(44, 47, 49, 0.3); 
  font-size: 3em; 
  pointer-events: none; 
  z-index: -1; 
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
}
.services-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  gap: 20px; 
 
}

.service-item {
  background: white; 
  padding: 15px;
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  transition: transform 0.2s;
  text-align: center; 
 
 
}
.service-item {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  text-align: center;
  position: relative; 
}

.service-item::after {
  content: ""; 
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%; 
  height: 5px; 
  background-color: #614f06; 
  border-radius: 4px;
}
.service-item:hover {
  transform: translateY(-5px); 
}

.service-item i {
  font-size: 36px; 
  color: #4b500a;
  margin-bottom: 10px;
  display: inline-block; 
  padding: 10px; 
  border-radius: 50%; 
  background-color: rgba(15, 93, 177, 0.1); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.2s, background-color 0.2s; 
}

.service-item i:hover {
  transform: scale(1.1); 
  background-color: rgba(15, 93, 177, 0.2); 
}

.service-item h3 {
  color: #151616; 
}
.service-item p {
  color: #333; 
  line-height: 1.6; 
}

/* Activities Section */
.activities-section {
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.activities-section h2 {
  text-align: center;
  color: #151616;
  font-size: 2rem;
  margin-bottom: 20px;
}
.button1-container {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 20px; 
}

.btn1 {
  width:80px;
  height: 50px;
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #706609;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}
.image-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  overflow: hidden; 
  padding: 20px; 
  width: 100%;
  box-sizing: border-box; 
}

.image-group {
  display: none; 
  opacity: 0;
  transition: opacity 0.5s ease;
}

.image-group.active {
  display: flex; 
  justify-content: center;
  gap: 10px; 
  width: 100%; 
  opacity: 1;
  margin: 0;
}

.image-group {
  display: none; 
  opacity: 0;
  transition: opacity 0.5s ease;
}
.image-group img {
  width: 500px; 
  height: auto; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}
/* Value Section */
.values-section {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}

.values-section h2 {
  color: #151616;
  margin-bottom: 20px;
}

.value-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.value-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
}

.value-item i {
  color: #3d5204;
  font-size: 30px;
}

.value-item h3 {
  color: #151616;
  margin: 10px 0;
}

.value-item p {
  color: #666;
  font-size: 14px;
}

.underline {
  height: 5px;
  margin-top: 10px;
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
/*Partners Section*/
.partners-section {
  background-color: #f4f4f4;
  padding: 40px 0;
  text-align: center;
  overflow: hidden;
}

.partners-section h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.partners-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.partners-container {
  display: flex;
  align-items: center;
}


.partner-item {
  flex: 0 0 auto;
  width: 200px; 
  height: 150px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.partner-item img {
  width: 100%; 
  height: 100%; 
  object-fit: contain; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.partners-container::after {
  content: '';
  display: flex;
  gap: 30px;
  margin-left: 0;
}
/*Footer*/
.footer {
  background-color: #fff9ef;
  padding: 40px;
  display: flex;
  flex-direction: column; 
  align-items: center;
}
.footer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px; 
  width: 100%; 
}

.footer-column {
  display: flex;
  flex-direction: column; 
}

.footer-column h1, .footer-column h3, .footer-column h4 {
  margin: 0 0 15px; 
  color: #614f06; 
}

.footer-column p {
  margin: 5px 0; 
}
.footer-nav ul {
  list-style: none;
  padding: 0; 
}

.footer-nav li {
  margin: 5px 0; 
}

.footer-nav a {
  text-decoration: none;
  color: #111010; 
  transition: color 0.3s ease; 
  text-align: center;
  align-items: center;
}
.footer-nav a:hover {
  color: #ffdd57; 
}

.map-container {
  margin-top: 20px; 
  display: flex;
  justify-content: center; 
}

.footer-logo {
  max-width: 100px; 
  margin-bottom: 10px; 
}
.footer-column h4 {
  color: #614f06;
}

.footer-column p {
  color: #0e0101; 
}
.footer-bottom {
  text-align: center; 

}

.footer-bottom p {
  margin: 0; 
  font-size: 14px; 
  color: #0e0101;
}
/* Projects Section */
.projects-section {
  margin: 20px;
}

.projects-section h2 {
  text-align: center;
  margin: 20px;
  color: #090c01;
  font-size: 1.4rem;
  text-shadow: 2px 2px 0 rgba(255, 255, 255, 0.5), 3px 3px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}

/* Project Card */
.project-card {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
}

.project-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, rgba(0, 123, 255, 0.2), rgba(255, 0, 150, 0.2));
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.project-card:hover::before {
  opacity: 1;
}

.project-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

.project-card h3 {
  margin: 0 0 10px;
  color: #333;
  font-size: 1.2rem;
  position: relative;
  z-index: 1;
}

.project-card p {
  color: #666;
  margin-bottom: 15px;
  z-index: 1;
  flex-grow: 1;
}

/* Button */
.see-more-button {
  padding: 12px 25px;
  border: none;
  background: linear-gradient(45deg, #007bff, #ff0099);
  color: white;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  z-index: 1;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-top: 20px; 
  align-self: center; 
}

.see-more-button:hover {
  background: linear-gradient(45deg, #0056b3, #d40077);
  transform: scale(1.1);
}
/* Jobs*/
.search-container {
  display: flex;
  justify-content: flex-end;
  margin: 30px;
}

.search-input {
  max-width: 50%;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 25px; 
  font-size: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  transition: border-color 0.3s, box-shadow 0.3s; 
}

.search-input:focus {
  border-color: #1f2902; 
  box-shadow: 0 4px 15px rgba(31, 41, 2, 0.3); 
  outline: none; 
}
h2{
  text-align: center;
}
.job-listings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px; 
}

.job-card {
  display: flex;
  flex-direction: column; 
  justify-content: space-between; 
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  border: 2px solid #867a06;
  outline: none;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.button-container {
  margin-top: auto; 
  display: flex;
  justify-content: center; 
  gap: 10px;
  padding: 20px 0;
}

.apply-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #4e4707;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;

}

.apply-button:hover {
  background-color: #645f0f;
}
.status.pending {
  color: #16be32; 

}

.status.closed {
  color: #d9534f; 
 
}

/* Application Section */
.applications-section {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  margin-top: 30px;
  padding: 0 20px;
  text-align: center;
}

.applications-section h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;  
}

.applications-section p {
  font-size: 16px;
  color: #666;
  font-style: italic;
  text-align: center;
  margin-top: 20px;
}

.application-card {
  width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; 
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  box-sizing: border-box;
}

.application-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-15px);
}

.application-card h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.application-card p {
  font-size: 16px;
  color: #555;
  margin: 5px 0;
}

.application-card p strong {
  color: #333;
}

/*Application*/
.application-form {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  background: linear-gradient(to right, #f0f8ff, #7a865f); 
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.application-form h2 {
 text-align: center;
 margin-bottom: 20px;
}
.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus {
  border-color: #0056b3;
}

.application-form button {
  background-color: #606118;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #696809;
}
/* Modal Styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); 
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.modal-content p {
  font-size: 16px;
  color: #555;
}

.modal-content i {
  margin-bottom: 15px;
  
}

.modal-content .close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.modal-content .close:hover {
  color: #333;
}

/* Animation for modal */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

.fas.fa-check-circle {
  color: green;
  font-size: 60px;
}
/*ApplicationList*/
.applications-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Error message styles */
.error-message {
  color: red;
  text-align: center;
}

/* Selection container */
.selection-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e0f7fa;
  border-radius: 8px;
}

/* Button styles */
button {
  padding: 10px 15px;
  font-size: 16px;
  background-color: #727409;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #82af07; 
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  color: #333;
}


/* Contact Section */
/*Gmail Details*/
#gmail-details {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding: 20px;
  margin: 0 auto; 
  font-size: 1.1rem;
}

#gmail-details p {
  display: flex;
  align-items: center;
  margin: 0;
  color: #333;
  font-weight: bold;
  text-decoration: underline;
  
}
#gmail-details p i{
  margin-right: 8px;
  color: #7c620c;
  font-size: 1.3rem;
  transition: color 0.3s ease;
 
}
.contact-section {
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap; 
  gap: 20px;
  align-items: flex-start;
  padding: 40px;
  background: linear-gradient(135deg, #f0f5f9, #e1e9ee); 
}

/* Contact Image Section */
.contact-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 25px;
  flex: 1; 
}

.contactus {
  max-width: 500px; 
  height: auto;
  box-shadow: 0 0 10px rgba(170, 154, 12, 0.5); 
}

.contact-info h3 {
  font-size: 1.8rem;
  color: #333;
  margin: 10px 0;
  font-weight: 700;
  text-transform: uppercase; 
}

.contact-info p {
  font-size: 1.5rem;
  color: #007bff; 
  font-weight: bold;
  letter-spacing: 0.5px;
}

.contact-form-container {
  
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
}

.contact-form-container h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}
.form-group i {
  color: #2f3805;
  margin-right: 8px;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #2f3805;
}

button[type="submit"] {
  display: inline-block;
  background-color: #2f3805;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

button[type="submit"]:hover {
  background-color: #2f3805;
}

.form-status {
  margin-top: 20px;
  font-size: 1rem;
  color: #2f3805;
  text-align: center;
  color: #14461f;
}
/*Whatsapp*/
.whatsapp-float {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25D366;
  color: white;
  font-size: 24px;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.whatsapp-float:hover {
  background-color: #128C7E;
  transform: scale(1.1);
}

.whatsapp-float i {
  margin: 0;
}
/*About Section*/
.about-section {
  background-color: #e8e8e8; 
  padding: 60px 0;
}
.about-content {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  gap: 20px;
}

.about-image,
.about-description {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  padding: 20px;
  box-sizing: border-box;
}

.about-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; 
}

.about-description {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
}

.about-description h2 {
  font-size: 32px;
  font-weight: bold;
  color: #665808;
  margin-bottom: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.about-description p {
  font-size: 18px;
  margin-bottom: 15px;
}

.about-description h3 {
  font-size: 26px;
  color: #333;
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}
.safety-health-section {
  padding: 40px 0;
  background-color: #f9f9f9;
}
.safety-health-section h2 {
text-align: center;
margin-bottom: 20px;
font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.safety-health-content {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  gap: 20px;
}

.safety-health-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.safety-health-description {
  flex: 1;
}

.image-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
  margin: 20px auto; 
  gap: 30px; 
  padding: 10px; 
  background-color: #f8f9fa; 
  border-radius: 15px; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.image-row img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 12px; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-row img:hover {
  transform: scale(1.05); 
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); 
  cursor: pointer; 
}

.statistics-section {
  background-color: #f7faff;
  padding: 60px 0;
}

.statistics-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  gap: 20px;
}

.stat-item h2 {
  font-size: 48px;
  font-weight: bold;
  color: #e7c00f;
  margin: 0;
}

.stat-item p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 10px 0 0;
}
 /* Ratings Section */
 .google-login {
  top: 10px;
  right: 10px;
  position: fixed;
  z-index: 999; 
  padding: 10px; 
}
.S9gUrf-YoZ4jf iframe {
  display: none !important;  
  position: relative !important;
  margin: 0 !important;       
  padding: 0 !important;      
  border: none !important;    
  height: 44px !important;     
  width: 100% !important;      
  overflow: hidden !important; 
}
.S9gUrf-YoZ4jf iframe[style*="overflow: hidden"] {
  display: none !important;   
}

  .ratings-page {
    padding: 20px;
    background-color: #f4f4f4;
  }
  .profile-menu {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000; 
  }
 
  .user-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #78800a;
    position: fixed;
    top: 20px; 
    right: 20px;
  }
  
  /* Menu dropdown */
  .menu-dropdown {
    position: fixed;  
    top: calc(20px + 50px + 10px);  
    right: 20px; 
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 150px;
    z-index: 9999;
  }
  
  .menu-item {
    padding: 10px 20px;
    cursor: pointer;
    text-align: left;
    color: #333;
    font-size: 14px;
  }
  .menu-item:hover {
    background-color: #f4f4f4;
  }
  .menu-item:last-child {
    border-top: 1px solid #ddd;
    color: #e74c3c;
  }
  .rating-section {
    margin-top: 30px;
  }
  .stars {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .star {
    font-size: 50px; 
    color: #3d3939;
    cursor: pointer;
    transition: color 0.3s ease; 
  }
  
  .star.filled {
    color: gold;
  }
  
  .star:hover {
    color: orange; 
  }
  .ratings-grid {
    margin-top: 50px;
  }
  .ratings-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; 
    padding: 0 20px; 
  }
  .ratings-grid h2 {
    margin: 20px;
  }
  .write {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px; 
    margin: 0 auto;
    text-align: center;
  }
  
  textarea {
    width: 80%; 
    max-width: 600px; 
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 16px;
    resize: vertical;
    outline: none;
  }
  
  textarea:focus {
    border-color: #49450d;
    box-shadow: 0 0 5px rgba(26, 115, 232, 0.5);
  }
  
  button {
    width: 80%; 
    max-width: 600px;
    background-color: #525309;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px; 
    text-align: center;
  }
  
  
  .rating-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .rating-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  .rating-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  
  .rating-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
  }
  
  .rating-info {
    display: flex;
    flex-direction: column;
  }
  
  .rating-email {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .rating-comment {
    font-size: 16px;              
    color: #333;                   
    background-color: #f9f9f9;     
    padding: 10px 15px;            
    border-radius: 8px;          
    margin-top: 10px;              
    line-height: 1.6;              
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);  
    max-width: 600px;              
    word-wrap: break-word;         
  }
  .comment-input {
    width: 100%;                    
    max-width: 600px;               
    padding: 10px 15px;             
    font-size: 16px;               
    color: #333;                    
    background-color: #f5f5f5;      
    border: 1px solid #ddd;         
    border-radius: 5px;           
    outline: none;                 
    transition: border-color 0.3s, box-shadow 0.3s; 
  }
  
  .comment-input:focus {
    border-color: #007bff;         
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.3); 
  }

/* BuildCostPro */
.container-section {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
  gap: 20px; 
  width: 100%;
  margin: 0 auto; 
  padding: 20px;
  align-items: start;
}

.container {
  max-width: 500px;
  width: 100%; 
  box-sizing: border-box;  
  overflow: hidden;
  margin: 20px auto;
  padding: 12px 25px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: linear-gradient(to right, #705418, #777771);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: white;
  
}

.container .formGroup {
  margin-bottom: 10px;
}

.container .label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.container .input,
.container .select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;  
}

.container .button {
  width: 100%;
  padding: 14px;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  background-color: rgb(49, 42, 11);
  box-sizing: border-box;  
}

.container .result {
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
  color: #333;
  font-weight: bold;
  height: 50px;  
  line-height: 50px; 
  overflow: hidden;  
  text-overflow: ellipsis; 
  white-space: nowrap;  
}

/* Card styling */
.intro-section .card {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box; 
}
.buildcostimage {
  max-width: 500px; 
  height: auto;
  border-radius: 8px; 
  object-fit: cover; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease-in-out; 
  margin-top: 20px;
}
.buildcostimage:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); 
  filter: brightness(1.1); 
  cursor: pointer; 
}
.intro-section .card h3 {
  font-size: 1.5rem;
  color: #0e0101;
  font-weight: 600;
  margin: 20px;
}

.intro-section .card p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.intro-section .card h4 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #333;
}

.intro-section .card ul {
  text-align: left;
  margin: 0 auto;
  display: inline-block;
  max-width: 600px;
}

.intro-section .card li {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 10px;
}

.intro-section .card p:last-child {
  margin-top: 20px;
  font-size: 1rem;
  font-style: italic;
  color: #777;
}
/*Materials*/
.materials-container {
  padding: 50px 20px;
  text-align: center;
  background: linear-gradient(135deg, #f4f4f4, #e9e9e9);
  font-family: 'Arial', sans-serif;
}

.materials-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.materials-intro {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 40px;
}

.materials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.material-card {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.material-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.material-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.material-details {
  padding: 20px;
  text-align: left;
}

.material-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 10px;
}

.material-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

/*Payments*/
.payments-page {
  position: relative;
}

.back-button {
  width: 20%;
  background-color: #201d1d;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 5px;
}
.payment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
.payment-summary {
  width: 48%;
  padding: 20px;
}

.payment-summary h2 {
  font-size: 1.5rem;
  color: #333;
  border-bottom: 2px solid #584a0a;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.payment-summary ul {
  list-style: none;
  padding: 0;
}

.payment-summary li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
}

.payment-summary li strong {
  font-size: 16px; 
  font-weight: bold; 
  color: black; 
  margin-bottom: 10px; 
}
.payment-summary li span{
  font-size: 18px; 
  font-weight: bold; 
  color: rgb(172, 63, 13); 
  
}
.payment-methods {
  width: 50%;
  background: #d1d1c9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.payment-methods h2 {
  font-size: 1.5rem;
  color: #333;
  border-bottom: 2px solid #584a0a;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
  cursor: pointer;
}

.form-group input {
  margin-right: 10px;
  accent-color: #0070ba;
}

#paypal-button-container {
  margin-top: 20px;
}

.stripe-form {
  margin-bottom: 0;
  text-align: center;
}

.payment-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.payment-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}
#paypal-button-container {
  display: block !important;
  width: 100%;
  height: auto;
  color: #090c01;
}
.stripe-form .button{
  width: 50%;
  background-color: #5f4d10;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 5px;
}
.payments-page .modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.payments-page .modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payments-page .modal-content h2 {
  font-size: 24px;
  color: #28a745;
}

.payments-page .modal-content p {
  font-size: 18px;
  color: #333;
}
/*Confirmation*/
.confirmation-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.confirmation-container {
  text-align: center;
  background: #fff;
  padding: 30px;
  border: 1px  solid gray;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

.confirmation-container h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #28a745; 
}

.confirmation-container p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.payment-details {
  text-align: left;
  margin-top: 20px;
}

.payment-details h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.payment-details ul {
  list-style: none;
  padding: 0;
}

.payment-details li {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.payment-details li strong {
  color: #333;
}

.home-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #63610f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.home-button:hover {
  background-color: #554908;
}

@media (min-width: 175px) and (max-width: 1024px) {

 .image-section {
   position: relative;
   text-align: center;
   padding: 20px; 
 }


 .image-section .background-image {
   width: 100%;
   height: auto;
 }
 
 .image-section .overlay-content {
   position: absolute;
   color: #fff;
   padding: 10px;
 }
 
 .image-section .overlay-content h1 {
   font-size: 1.8em; 
 }
 
 .image-section .overlay-content p {
   font-size: 1em;
 }
 
 .info-section {
   display: flex;
   flex-direction: column;
   padding: 20px;
   text-align: center;
 }
 
 .info-section .info-column {
   margin-bottom: 20px;
 }
 /*Service Section*/
 .services-section {
   padding: 40px 20px; 
   text-align: center;
   background-color: #f0f4f8; 
   border-radius: 8px; 
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
 }
 
 .services-section h2 {
  font-size: 2.5em; 
  color: #333; 
  margin-bottom: 20px; 
  text-transform: uppercase; 
  letter-spacing: 1px; 
}
 
 .services-list {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
   gap: 20px; 
 }
 
 .service-item {
   padding: 20px; 
   background-color: #ffffff; 
   border-radius: 8px; 
   text-align: center;
   transition: transform 0.3s, box-shadow 0.3s; 
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 }
 .services-section h2::after {
  content: ""; 
 }
 .service-item:hover {
   transform: translateY(-5px); 
   box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
 }
 
 .service-item h3 {
   font-size: 1.5em; 
   color: #210e3f; 
   margin: 10px 0;
 }
 
 .service-item p {
   font-size: 0.95em; 
   color: #666; 
   line-height: 1.5; 
 }
   /* Aktiviteti Ynë */
   .activities-section h2 {
     font-size: 2em; 
   }
 
   .image-container {
     display: flex;
     flex-direction: column; 
     align-items: center; 
   }
 
   .image-group img {
     width: 90%; 
     margin-bottom: 10px; 
   }
 
   .button-container {
     display: flex;
     flex-direction: row;
     justify-content: center; 
     margin-top: 15px; 
   }
 
   .btn {
     margin: 0 5px; 
   }
 /*Our Values */
.values-section h2 {
  font-size: 2em; 
  }
  .value-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  }
  .value-item {
  margin-bottom: 20px; 
  text-align: center;
  max-width: 100%;
  }

 /* partners section  */
 .partners-container {
  gap: 15px;
  overflow-x: auto; 
}
.partner-item {
  flex: 0 0 calc(100% / 6); 
  margin-right: 10px;
}
 /* Footer-i */
 .footer-content {
  grid-template-columns: repeat(2, 2fr); 
  gap: 50px; 
  padding: 20px;
  }
    
  .footer-column h1, .footer-column h3, .footer-column h4 {
  font-size: 18px; 
  margin: 10px 0;
  }
    
  .footer-nav a {
  font-size: 14px; 
  }
    
  .footer-column p {
  font-size: 14px; 
   margin: 8px 0;
  }
    
  .footer-bottom p {
   font-size: 12px; 
  }
  /* Map */
  .map-container {
  width: 100%;
  max-width: 1200px; 
  margin: 10px ; 
  border-radius: 10px; 
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
   
  .map-container iframe {
  width: 100%; 
  height: 300px; 
  border: none; 
  }

 /* Projects Section*/
 .projects-section {
   padding: 15px; 
 }
 
 .projects-section h2 {
   font-size: 1.8em; 
 }
 
 .projects-grid {
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
 }
 
 .project-card h3 {
   font-size: 1.3em; 
 }
 
 .project-card p {
   font-size: 0.9em; 
 }
 
 .see-more-button {
   padding: 8px 12px; 
   font-size: 0.9em; 
 }
 /*Jobs Section*/
 .search-container {
  margin: 20px auto; 
  display: flex;
  justify-content: center;
  width: 100%; 
}

/* Search Input */
.search-input {
  max-width: 90%; 
  padding: 15px 20px; 
  border: 1px solid #ccc; 
  border-radius: 30px; 
  font-size: 1.2rem; 
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15); 
  transition: all 0.3s ease; 
}

.search-input:focus {
  border-color: #867a06; 
  box-shadow: 0 4px 20px rgba(134, 122, 6, 0.4); 
  outline: none;
}

.job-listings {
  grid-template-columns: 1fr; 
  padding: 15px; 
}

.job-card {
  padding: 15px; 
}

h2 {
  font-size: 1.4rem; 
}
/*Application Section*/
.application-card h3 {
 text-align: center;
}
/*Contact*/
.contact-section {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  flex-direction: column; 
  padding: 20px; 
  gap: 30px; 
}
.contact-img {
  margin: 20 auto; 
  padding: 20px;
  width: 100%; 
}

/* Contact Form Container */
.contact-form-container {
  padding: 20px;
  box-shadow: none; 
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto; 
}

.contact-form-container h1 {
  font-size: 1.5rem; 
  margin-bottom: 15px; 
}

/* Form Fields */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 0.9rem; 
  margin-bottom: 5px; 
}

.form-group input,
.form-group textarea {
  padding: 10px;
  font-size: 0.9rem; 
}

/* Submit Button */
button[type="submit"] {
  padding: 10px 20px; 
  font-size: 1rem;
  width: 100%; 
}

/* Form Status */
.form-status {
  font-size: 0.9rem; 
}
 /* About Section */
 .about-section {
  padding: 40px 0; 
}

.about-content {
  flex-direction: column; 
  max-width: 100%; 
  gap: 20px; 
}

.about-image, .about-description {
  padding: 10px; 
  width: 100%; 
  text-align: center; 
}

.about-image img {
  max-width: 100%;
  height: auto; 
}

.about-description h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.about-description p {
  font-size: 16px; 
  margin-bottom: 10px; 
}

.about-description h3 {
  font-size: 22px; 
  margin-top: 20px; 
}

/* Safety and Health Section */
.safety-health-section {
  padding: 30px 0; 
}

.safety-health-content {
  flex-direction: column;
  max-width: 100%;
  text-align: center; 
  gap: 15px; 
}

.safety-health-image img {
  max-width: 100%; 
  height: auto;
}

.image-row {
  flex-direction: column; 
  gap: 20px; 
  margin: 20px 0; 
}

.image-row img {
  max-width: 100%;
  box-shadow: none; 
}

/* Statistics Section */
.statistics-section {
  padding: 40px 0; 
}

.statistics-content {
  flex-direction: column; 
  gap: 20px;
  max-width: 100%; 
}

.stat-item h2 {
  font-size: 36px; 
}

.stat-item p {
  font-size: 16px; 
}
/* Application Form */
.application-form {
  max-width: 90%; 
  margin: 20px auto;
  padding: 15px;
  border-radius: 8px; 
}

.application-form h2 {
  font-size: 22px;
  margin-bottom: 15px; 
}

.form-group {
  margin-bottom: 10px; 
}

label {
  font-size: 14px; 
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="file"] {
  padding: 8px; 
  font-size: 14px; 
}

.application-form button {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 10px; 
  font-size: 16px;
}

/* Modal Styles */
.modal {
  justify-content: center; 
  align-items: center;
  padding: 10px; 
}

.modal-content {
  max-width: 90%; 
  padding: 15px; 
}

.modal-content h2 {
  font-size: 20px; 
  margin-bottom: 15px;
}

.modal-content p {
  font-size: 14px; 
}

.modal-content i {
  font-size: 30px;
  margin-bottom: 10px; 
}

.modal-content .close {
  font-size: 20px; 
}
/* ApplicationsList container */
.applications-container {
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}

h2 {
  text-align: center;
  font-size: 20px; 
  margin-bottom: 20px;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 14px;
}

/* Hide table header on smaller screens */
thead {
  display: none;
}

tbody tr {
  display: block; 
  margin-bottom: 15px;
  border: 1px solid #ddd; 
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}

/* Individual data cells are displayed in block */
tbody td {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #f1f1f1;
}

/* Remove bottom border for the last item */
tbody td:last-child {
  border-bottom: none;
}

/* Labels for each data item, appears before the actual data */
tbody td::before {
  content: attr(data-label); 
  font-weight: bold;
  color: #555;
  flex-basis: 40%;
}

tbody td a {
  color: #007bff; 
  text-decoration: none;
}

tbody td a:hover {
  text-decoration: underline;
}

/* Checkbox (favorite) */
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Selected row highlight */
tbody tr.selected {
  background-color: #e0f7fa;
}

/* Selection container for selected application details */
.selection-container {
  text-align: center;
  margin-top: 20px;
}

.selection-container h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

/* Error message styling */
.error-message {
  color: red;
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;
}

/* Button Styling */
button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #218838;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
}

button:hover {
  background-color: #1e7e34;
}
/*Ratings*/
.user-image {
  width: 45px;
  height: 45px;
  top: 0;
  right: 10px; 
}

.menu-dropdown {
  top: 80px; 
  width: 180px;
}
.google-login {
  top: 0;
  right: 0;
  padding: 0; 
  margin-bottom: 10px;
  width: 50%;
}
.S9gUrf-YoZ4jf iframe {
  display: block !important;  
}
.profile-menu {
  top: 0px; 
  right: 10px;
}

.menu-item {
  padding: 8px 16px;
}

.rating-section {
  margin-top: 20px;
}
.rating-section h2{
  margin-top: 30px;
}
.stars {
  margin-bottom: 10px;
}

.star {
  font-size: 30px;
}

.ratings-grid {
  margin-top: 40px;
}

.ratings-list {
  grid-template-columns: repeat(2, 1fr); 
  gap: 15px; 
  padding: 0 15px; 
}

.rating-item {
  padding: 15px; 
  flex-direction: column; 
  align-items: center; 
  box-sizing: border-box; 
}

.rating-avatar {
  width: 40px; 
  height: 40px;
  margin-bottom: 10px; 
}

.rating-info {
  text-align: center; 
}

.rating-email {
  font-size: 12px; 
  margin-bottom: 5px;
}

.rating-comment {
  font-size: 12px;
  color: #777; 
  margin-top: 5px;
}

.write {
  width: 90%; 
}

textarea {
  width: 90%;
  font-size: 14px; 
}

button {
  width: 90%;
  font-size: 14px;
}
/*BuildCostPro*/
.container-section {
  grid-template-columns: 1fr; 
  padding: 15px;
 
}

.container {
width: 100%;
padding: 20px;
max-width: 90%;
}

.container .input,
.container .select,
.container .button {
  font-size: 14px;
  padding: 10px; 
  align-items: center;
  margin:0 auto;
}

.container .result {
  font-size: 16px; 
  height: 40px; 
}

.intro-section .card {
  padding: 10px; 
}

.intro-section .card h3 {
  font-size: 1.8rem; 
  text-align: center;
}

.intro-section .card p {
  font-size: 1rem; 
}

.intro-section .card ul {
  max-width: 100%; 
}

.intro-section .card li {
  font-size: 1rem; 
}

.intro-section .card p:last-child {
  font-size: 0.9rem; 
}
.intro-section .card {
  width: 100%;
  margin: 0 auto;

}
.buildcostimage{
  width: 100%;
  margin:  auto 10px;
  display: flex;
  justify-content: center;
  max-width: 100%;
}
/*Payments*/
.payments-page {
  width: 100%;
  font-size: 14px;
  padding: 8px 16px;
  margin: 0 auto;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  justify-content: center; 
  max-width:100%;
}
.back-button {
  width: 20%;
  padding: 10px 20px;
  font-size: 12px;
 
}
.payment-container {
  flex-direction: column; 
  gap: 10px; 
  padding: 15px;
}

.payment-summary, 
.payment-methods {
  width: 100%; 
  padding: 15px; 
}

.payment-summary h2, 
.payment-methods h2 {
  font-size: 1.25rem; 
}

.payment-summary li, 
.payment-methods label {
  font-size: 0.875rem; 
}

.payment-logo {
  width: 40px; 
}

#paypal-button-container, 
.stripe-form .button {
  width: 100%; 
}

.modal-content {
  width: 90%; 
  padding: 20px; 
}

.confirmation-container {
  width: 95%; 
  padding: 20px;
}

.home-button {
  padding: 8px 16px; 
  font-size: 14px;
} 
}
