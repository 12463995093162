/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f7fc;
  color: #333;
}

/* Event Calendar Container */
.event-calendar-container {
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
}

/* Header */
h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

/* Add Event Button */
.add-event-button {
  background-color: #5f610a;
  color: white;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.add-event-button:hover {
  background-color: #5f610a;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.event-form {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 500px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-form h2 {
  color: #5d630b;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.event-form label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
  font-weight: bold;
  color: #555;
}
.event-form input,
.event-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  margin-top: 5px;
}

.event-form input[type="datetime-local"] {
  font-family: 'Arial', sans-serif;
}

.event-form textarea {
  height: 100px;
  resize: vertical;
}

.event-form button {
  background-color: #5f610a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.event-form button:hover {
  background-color: #5f610a;
}

.event-form button[type="button"] {
  background-color: #f44336;
  margin-left: 10px;
}

.event-form button[type="button"]:hover {
  background-color: #e53935;
}

/* Calendar Styles */
.rbc-calendar {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rbc-header {
  background-color: #5f610a;
  color: white;
  font-weight: bold;
  text-align: center;
}

.rbc-day-bg {
  background-color: #fcf4f4 !important;
}

.rbc-day-bg:hover {
  background-color: #e8f5e9 !important;
}

.rbc-event {
  background-color: #5f610a;
  color: white;
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
}

.rbc-event:hover {
  background-color: #5f610a;
}

.rbc-selected {
  background-color: #5f610a !important;
}
.back-button {
  background-color: #464946; 
  color: white;
  padding: 10px 20px; 
  font-size: 12px; 
  border: none;
  border-radius: 5px; 
  cursor: pointer;
  position: fixed; 
  top: 20px; 
  left: 20px; 
  z-index: 10; 
  transition: background-color 0.3s ease; 
}
/* Responsive Styles */
@media (max-width: 768px) {
  .event-calendar-container {
    width: 90%;
    padding: 20px 0;
  }

  .event-form {
    width: 90%;
  }
}