/* Cookie Consent Banner Style */
.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    text-align: center;
    padding: 20px;
    z-index: 1000;
    font-family: Arial, sans-serif;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .cookie-banner p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .cookie-options {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .cookie-options div {
    margin-bottom: 10px;
  }
  
  .cookie-options input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .cookie-banner-button {
    display: flex;
    flex-direction:row;
    justify-content: center;
    gap: 50px;
    width: 100%;
  }
  
  .cookie-banner-button button {
    background-color: #5a5906;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .button button:hover {
    background-color: #4b4705; 
  }
  
  .cookie-banner button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .cookie-banner label {
    font-size: 14px;
    color: #ddd;
  }
  
  .cookie-banner .cookie-options {
    margin-bottom: 20px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .cookie-banner {
      padding: 15px;
    }
  
    .cookie-banner p {
      font-size: 14px;
    }
  
    .cookie-options div {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .cookie-banner button {
      width: 100%;
      font-size: 14px;
      padding: 12px;
    }
  }
  
  /* iPad (Tablet) Responsiveness */
  @media (min-width: 769px) and (max-width: 1024px) {
    .cookie-banner {
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .cookie-banner p {
      font-size: 16px;
    }
  
    .cookie-options div {
      flex-direction: column;
      align-items: center;
    }
  
    .cookie-banner button {
      width: 80%;
      font-size: 16px;
      padding: 12px 25px;
    }
  }