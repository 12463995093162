.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff9ef;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: background 0.3s;
  width:100%;
 
}

.logo img {
  width: 130px;
  height: auto;
  border-radius: 8px;
}
.hamburger {
  cursor: pointer;
  display: none; 
  flex-direction: column;
  gap: 5px;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}
/*Menu*/
.menu {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
  justify-content: flex-start; 
}

.menu li {
  position: relative;
  text-decoration: none;
  color: #7c620c;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  margin-left: 20px;
  font-size: 22px;
}

.menu li a {
  color: #554d07;
  font-size: 1.5rem;
  font-weight: 500;
  transition: color 0.3s ease;
  text-decoration: underline;
}

.menu li a, .menu li span {
  color: #554d07; 
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
}

.menu li a:hover, .menu li span:hover, .menu li a.active, .menu li span.active {
  color: #fcd913;
  text-decoration: underline;
}

/* Styling for Projects menu */
.projects {
  position: relative;
  display: inline-block; 
}

.projects .dropdown {
  display: none;
  position:fixed; 
  background-color: #a09b82;
  list-style: none;
  padding: 5px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 150px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
  left: 48%; 
  transform: translateX(-50%) translateY(10px);
  margin-top: 10px;
}

.projects:hover .dropdown {
  display: block; 
  opacity: 1;
  visibility: visible;
  transform: translateX(-80%) translateY(0); 
}
.projects:hover .dropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0); 
}
.projects .dropdown li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}

.projects .dropdown li:last-child {
  border-bottom: none;
}
.projects .dropdown a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  display: block;
  border-radius: 5px;
  align-items: flex-start;
  color: white;
}
.projects .arrow {
  display: none; 
  margin-left: 5px;
  transition: transform 0.3s ease;
  font-size: 24px; 
  font-weight: bold; 
  color: #dddbcc; 
  transition: transform 0.3s ease, color 0.3s ease;
  cursor: pointer; 
}
.projects .arrow.up {
  transform: rotate(0deg);
}

.projects .arrow.down {
  transform: rotate(180deg);
}


.home {
  position: relative;
  display: inline-block; 
}

.home .dropdown {
  position: fixed;
  background-color: #5b6e24;
  list-style: none;
  padding: 5px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 150px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
  left: 36%; 
  transform: translateX(-50%) translateY(10px);
  margin-top: 10px;
}

.home:hover .dropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0); 
}
.home:hover .dropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0); 
}
.home .dropdown li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}

.home .dropdown li:last-child {
  border-bottom: none;
}
.home .dropdown a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  display: block;
  border-radius: 5px;
  align-items: flex-start;
  color: white;
}
.home .arrow {
  display: none; 
  margin-left: 5px;
  transition: transform 0.3s ease;
  font-size: 24px; 
  font-weight: bold; 
  color: #dddbcc; 
  transition: transform 0.3s ease, color 0.3s ease;
  cursor: pointer; 
}
.home .arrow.up {
  transform: rotate(0deg);
}

.home .arrow.down {
  transform: rotate(180deg);
}

@media (min-width: 175px) and (max-width: 1024px) {
  /*Header*/
  .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 20px;
 }
 
.logo img {
  width: 120px;
}
.hamburger {
  display: flex; 
  cursor: pointer;
  z-index: 1001; 
  padding: 10px; 
  transition: transform 0.3s; 
}

.hamburger:hover {
  transform: scale(1.1); 
}

.hamburger span {
  background-color: #090c01;
  height: 4px; 
  width: 30px; 
  margin: 3px 0; 
  transition: background-color 0.4s, transform 0.4s; 
}
.hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active span:nth-child(2) {
  opacity: 0; 
}

.hamburger.active span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px); 
}
.menu {
  display: none; 
  flex-direction: column;
  width: 60%; 
  position: absolute;
  top: 70px; 
  right: 0;
  background: linear-gradient(to right, #1a1a1a, #4a90e2, #6ab04c); 
  border-top: 1px solid #eaeaea; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); 
  z-index: 999; 
  height: auto;
  border-radius: 5px; 
  visibility: hidden;
  opacity: 0; 
  transition: visibility 0s 0.3s, opacity 0.3s ease; 
}

.menu.open {
  display: flex;
  visibility: visible; 
  opacity: 1;
  animation: slideDown 0.3s ease;
}


@keyframes slideDown {
from {
opacity: 0;
transform: translateY(-10px); 
}
to {
opacity: 1;
transform: translateY(0); 
}
}
.menu li  {
  padding: 15px 20px; 
  border-bottom: 1px solid #eaeaea; 
  text-align: center; 
  color: #ffffff; 
  transition: background-color 0.3s; 
  margin: 0; 
}

.menu li:last-child a {
  border-bottom: none; 
}
.projects .arrow {
  display: inline-block; 
}
.projects .dropdown {
  background: none;
  position: relative;
  left: 70%; 
  transform: translateX(-50%); 
  width: 80%; 
  top: 100%; 
  z-index: 1000; 
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.projects:hover .dropdown {
  visibility: visible;
  opacity: 1;
}
.home .arrow {
  display: inline-block; 
}
.home .dropdown {
  background: none;
  position: relative;
  left: 70%; 
  transform: translateX(-50%); 
  width: 80%; 
  top: 100%; 
  z-index: 1000; 
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.home:hover .dropdown {
  visibility: visible;
  opacity: 1;
}
}

